import html2canvas from 'html2canvas';
import React, {useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoIcon from "../components/icon/Logo";
import {colors} from "../styles/color";
import jsPDF from "jspdf";
import Box from "@mui/material/Box";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

const Wrapper = styled.div`
	width: 1600px;
	margin: 0 auto;
`;

const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1.6rem 8rem;
	border-bottom: 0.1rem solid var(--gray-300);
	margin-bottom: 28px;
`;

const LogoSection = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 12px;
`;

const HeaderTitle = styled.span`
	color: var(--Grey-900, #252525);
	text-align: center;

	font-size: 24px;
	font-style: normal;
	font-weight: 400;
`;

const DownloadButton = styled.button`
	display: flex;
	align-items: center;
	font-size: 12px;
	gap: 4px;
	
	&:hover {
		color: ${colors.neutral['500']};	
	}	
`;

const NavigationWrapper = styled.div`
	display: flex;
	width: 1440px;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	margin: 0 auto;
	flex-wrap: wrap;	
`;

const Navigation = styled.nav`
	display: flex;
	width: 638px;
	align-items: center;
	gap: 20px;
	flex-shrink: 0;	
`;

const NavItem = styled.button<{ isActive?: boolean }>`
	display: flex;
	padding: 4px;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	gap: 8px;
	color: ${props => props.isActive ? 'var(--grey-grey-050, #FBFBFB)' : '#252525'};
	background: ${props => props.isActive ? 'var(--grey-grey-900, #252525)' : 'transparent'};
	border-radius: ${props => props.isActive ? '2px' : '0'};
	border: none;
	cursor: pointer;
	
	&:hover {
		color: ${props => props.isActive ? 'var(--grey-grey-050, #FBFBFB)' : colors.neutral['500']};
	}
`;

const Content = styled.div`
	padding: 8rem 38rem;

	h2 {
		font-size: 2rem;
		margin-bottom: 2rem;
		color: var(--gray-900);
	}

	p {
		color: var(--grey-grey-900, #252525);
		font-size: 14px;
		line-height: 21px; /* 21px */
		margin-bottom: 1.6rem;
	}
`;

interface SectionProps {
	marginTop?: number;
}

const MainSection = styled.div`
	color: var(--grey-grey-900, #252525);
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 33.6px */
`;

const SubSection = styled.div`
	color: var(--grey-grey-900, #252525);

	/* Body1/Bold */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 19.2px */
`;

const StyledLink = styled.a`
  color: var(--Primary-Violet-300-Primary, #9747FF);
  text-decoration: underline;
`;

interface SectionProps {
	marginTop?: number;
}

const Section = styled.section<SectionProps>`
  margin-top: ${props => props.marginTop ?? 40}px;

  p {
    display: flex;
    gap: 8px;
    color: var(--grey-grey-900, #252525);
    font-size: 14px;
    line-height: 150%; /* 21px */
    font-family: Inter;
    
    &.nested {
      margin-left: 24px;
      // border 제거
      
      // 로마 숫자 리스트
      &.roman {
        margin-left: 48px;
      }
    }
  }
`;

const TermsOfServicePage = () => {
	const [searchParams] = useSearchParams();
	const sectionParam = searchParams.get('section');
	const [activeTab, setActiveTab] = useState(sectionParam || 'General Provisions');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	// sectionParam 값에 따라 해당 섹션으로 스크롤
	useEffect(() => {
		if (sectionParam) {
			setActiveTab(sectionParam);
			const element = document.getElementById(sectionParam.replace(/\s+/g, '-').toLowerCase());
			if (element) {
				// 약간의 지연을 주어 페이지가 완전히 로드된 후 스크롤
				setTimeout(() => {
					element.scrollIntoView({ behavior: 'smooth' });
				}, 100);
			}
		}
	}, [sectionParam]);

	const handleTabClick = (tabName: string) => {
		setActiveTab(tabName);
		// id로 스크롤
		const element = document.getElementById(tabName.replace(/\s+/g, '-').toLowerCase());
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handleDownloadPDF = async () => {
		setLoading(true);
		const content = document.getElementById('terms-content');
		if (!content) return;

		try {
			const pdf = new jsPDF('p', 'mm', 'a4');
			const pageHeight = pdf.internal.pageSize.getHeight();
			const pageWidth = pdf.internal.pageSize.getWidth();

			// content를 이미지로 변환
			const canvas = await html2canvas(content, {
				scale: 2,  // 해상도 향상
			});

			const imgData = canvas.toDataURL('image/png');
			const imgWidth = pageWidth;
			const imgHeight = (canvas.height * pageWidth) / canvas.width;

			let heightLeft = imgHeight;
			let position = 0;

			// 첫 페이지 추가
			pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
			heightLeft -= pageHeight;

			// 남은 높이가 있으면 페이지를 추가하며 이미지 계속 그리기
			while (heightLeft >= 0) {
				position -= pageHeight;
				pdf.addPage();
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
			}

			pdf.save('terms-of-service.pdf');
		} catch (error) {
			console.error('PDF generation failed:', error);
		} finally {
			setLoading(false);
		}
	};

	const moveMainPage = () => {
		navigate('/');
	}

	return (
		<Wrapper>
			<div>
				{loading && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: 'rgba(255, 255, 255, 0.7)',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							zIndex: 1000,
						}}
					>
						<Box sx={{ display: 'flex' }}>
							<CircularProgress disableShrink />
						</Box>
					</div>
				)}
			</div>

			<HeaderWrapper>
				<LogoSection>
					<div onClick={moveMainPage} style={{ cursor: "pointer" }}>
						<LogoIcon/>
					</div>
					<HeaderTitle>Privacy & Terms of Use</HeaderTitle>
				</LogoSection>
			</HeaderWrapper>
			<NavigationWrapper>
				<Navigation>
					<NavItem
						isActive={activeTab === 'General Provisions'}
						onClick={() => handleTabClick('General Provisions')}
					>
						General Provisions
					</NavItem>
					<NavItem
						isActive={activeTab === 'Services'}
						onClick={() => handleTabClick('Services')}
					>
						Services
					</NavItem>
					<NavItem
						isActive={activeTab === 'Data Management and Privacy'}
						onClick={() => handleTabClick('Data Management and Privacy')}
					>
						Data Management and Privacy
					</NavItem>
					<NavItem
						isActive={activeTab === 'Legal matters'}
						onClick={() => handleTabClick('Legal matters')}
					>
						Legal matters
					</NavItem>
					<NavItem
						isActive={activeTab === 'Marketing'}
						onClick={() => handleTabClick('Marketing')}
					>
						Marketing
					</NavItem>
				</Navigation>
				<DownloadButton onClick={handleDownloadPDF}>
					<img src={'svg/download.svg'}/>
					Download
				</DownloadButton>
			</NavigationWrapper>
			<Content id="terms-content">
				<p>
					The purpose of these terms and conditions is to define the terms and conditions of use, procedures, and the
					rights, obligations, and responsibilities of users and the company regarding the use of Celevu, a celebrity
					image usage contract brokerage platform operated by Aligo AI Inc. (hereinafter referred to as "Aligo").
				</p>

				<Section id="general-provisions">
					<MainSection>I. General Provisions</MainSection>
				</Section>

				<Section marginTop={40}>
					<SubSection>1. Effectiveness and Amendment of the Terms</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>The contents of these terms and conditions are published on the Celevu website or communicated through other means and become effective for all users upon their agreement during registration.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>The company reserves the right to amend these terms, provided such amendments do not contravene applicable laws. In the event of amendments, the company shall notify users of the changes either within the service or via email at least 15 days prior to the effective date.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>The amended terms shall take effect immediately upon announcement. If a user does not raise an objection within seven days of the notification, they shall be deemed to have consented to the amended terms. Users who do not agree to the amended terms may proceed with account deletion.</span>
					</p>
				</Section>

				<Section marginTop={40}>
					<SubSection>2. Definitions</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>The terms used in these terms and conditions are defined as follows:</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Service: Refers to all advertising contract brokerage services provided by the company through <StyledLink
							href="https://www.celevu.ai">www.celevu.ai</StyledLink></span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>User: Refers to a person who has entered into a service use contract with the company and registered as a user.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Types:</span>
					</p>
				</Section>

				<Section marginTop={2}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>i.</span>
						<span>Advertiser: Includes advertising agencies and advertisers.</span>
					</p>
				</Section>

				<Section marginTop={2}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>ii.</span>
						<span>Agency: Includes model and celebrity agencies or management companies representing celebrities.</span>
					</p>
				</Section>

				<Section marginTop={2}>  {/* 20px에서 12px로 변경 */}
					<p className="nested roman">
						<span>iii.</span>
						<span>Scouter: Refers to an individual who registers a celebrity on this service.</span>
					</p>
				</Section>

				<Section marginTop={1}>  {/* 0px에서 12px로 변경 */}
					<p className="nested roman">
						<span>iv.</span>
						<span>Users may select their type during registration and must submit necessary documents, such as a business registration certificate or bank account details, to verify their identity before generating advertising contracts.</span>
					</p>
				</Section>

				<Section id="services" marginTop={80}>
					<MainSection>II. Services</MainSection>
				</Section>

				<Section marginTop={40}>
					<SubSection>3. Registration</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>All users must provide required information on the registration page and agree to the terms and privacy
						policy to register for Celevu.</p>
				</Section>

				<Section marginTop={20}>
					<div>
						<span>1.</span>
						<span>Required Information:</span>
					</div>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Company name</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Job title</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>Company email address</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Account Approval and Registration: The company will review the provided information and may request additional documents before approving the account.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Registration Documents:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: Copy of business registration certificate</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Agency:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Business registration certificate, bank account copy</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>(Optional) Celebrity's name, ID number, and address *Please refer to Article 6 for more information.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>4.</span>
						<span>Users must ensure that the provided information is accurate and are responsible for managing their account information and security.</span>
					</p>
				</Section>

				<Section marginTop={40}>
					<SubSection>4. Provided Services</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>The company provides following services:</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Online Portrait Rights Usage Contracts:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Overview:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Advertisers can use celebrity portraits for various online media.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Agencies must provide celebrity images and agree to their usage according to the contract.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>Advertisers may edit and modify the provided images but must adhere to the detailed contract terms.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iv.</span>
						<span>The detailed contract terms (e.g., portrait rights usage period, media, advertising region) are finalized through mutual agreement among the advertiser, agency, and celebrity within the Celevu platform.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Post-Management: The advertiser shall make every effort to ensure that the celebrity's image is not inappropriately used after the expiration of the portrait rights usage contract period and shall take all reasonable measures to this end.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>Media Usage:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Online pages (URL)</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Online advertisements (e.g., Instagram, Facebook)</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>Emails or newsletters</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iv.</span>
						<span>Other media usages may be arranged through additional consultations.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Social Media Content Posting Contracts:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Overview:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Celebrities will produce and post content related to the advertiser's product or brand on their own social media accounts.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>After posting, the link to the post must be registered on the Celevu contract page.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The detailed contract terms (e.g., SNS account name, posting frequency, posting duration) are finalized through mutual agreement between the advertiser and the agency within the Celevu platform.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Media Usage:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Instagram</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Youtube</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If the advertiser requests the use of other media, it can be arranged through additional consultations on the platform.</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Additional Services:</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Event Invitations</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Brand model or ambassador contracts</span>
					</p>
				</Section>

				<Section marginTop={20}>
					<p>All services provided can be carried out in multiples, and the related contracts are concluded exclusively
						within the Celevu platform.</p>
				</Section>

				<Section marginTop={40}>
					<SubSection>5. Contract Execution</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>The advertiser, agency, and celebrity shall enter into a contract by providing other required legal
						information necessary for drafting the advertising contract and signing electronically within the
						service.</p>
				</Section>

				<Section marginTop={40}>
					<SubSection>6. Payment and Settlement Procedures</SubSection>
				</Section>

				<Section marginTop={20}>
					<p>The timing of contract fee payment and the procedures for settlement shall vary depending on the type of
						contracted service. The detailed provisions are as follows.</p>
				</Section>

				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Online Portrait Rights Usage Contracts:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: The advertiser shall remit 108% of the contract amount to Aligo within two weeks from the date of contract execution (the date on which the celebrity accepts the contract) following the submission of the contract.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Agency.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>The fee shall be paid within two weeks from the date on which the celebrity confirms the finalized advertisement after its review.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>The fee shall be directly paid to the agency as previously agreed, and once Aligo remits the fee to the agency, it shall be deemed to have fulfilled its obligation to pay the portrait rights fee to both the agency and the celebrity.</span>
					</p>
				</Section>

				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The fee shall be calculated by deducting an 8% service fee and any applicable remittance or other fees from Aligo’s net revenue, with the remaining amount paid to the agency.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Social Media Content Posting Contracts:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Advertiser: The advertiser shall remit 108% of the contract amount to Aligo within two weeks from the date of contract execution (the date on which the celebrity accepts the contract) following the submission of the contract.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Agency.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Once the celebrity posts the finalized content on their personal social media account and uploads the corresponding link to the platform, the fee shall be paid within two weeks of the posting date after the advertiser verifies the post.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>The fee shall be directly paid to the agency as agreed in advance. Once Aligo remits the fee to the agency, it shall be deemed to have fulfilled its obligation to pay the performance fee to both the agency and the celebrity.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>The fee shall be calculated by deducting an 8% service fee and any applicable remittance or other fees from Aligo’s net revenue, with the remaining amount paid to the agency.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Payment Currency and Method:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>The advertiser may determine the currency and payment method at the time of remitting the contract fee. The payable amount shall be calculated based on the exchange rate applicable on the payment date.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>7. Restriction on Assignment of Rights and Obligations</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>None of the parties to this contract may transfer, delegate, or otherwise dispose of their rights and
						obligations under these terms and the contract to any third party without prior written consent from all
						parties involved</p>
				</Section>

				<Section marginTop={40}>
					<SubSection>8. Termination of Contract and Refund</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Termination and Cancellation of Contract</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>If any of the following circumstances occur to one of the parties to this contract, the other party may unilaterally terminate or cancel the entirety or a part of this contract:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>If one party violates the terms stipulated in this contract and fails to remedy the breach within 15 business days of receiving a written demand for rectification from the other party.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>If one party becomes unable to fulfill the obligations stipulated in this contract due to the initiation of rehabilitation procedures, bankruptcy, dissolution, liquidation, closure of business, or other similar circumstances.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If one party is unable to fulfill the obligations stipulated in this contract due to the attachment, provisional seizure, auction, or similar action taken against its primary assets.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iiii.</span>
						<span>If performance of mutual contractual obligations or the continuation of business becomes impossible due to force majeure events beyond control, such as war, civil commotion, earthquakes, typhoons, floods, storms, pandemics, or other extreme weather or emergency disaster situations.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iiiii.</span>
						<span>If the advertiser or the celebrity, during the contract period, violates criminal laws or becomes involved in socially reprehensible incidents that damage the reputation or image of the advertiser or the celebrity, the contract may be terminated by mutual agreement.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Refund Procedure</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Depending on the stage of contract termination, the advertiser may receive the following refunds from Aligo:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Before final approval of the advertisement: The advertiser is entitled to a 100% refund of the contract amount paid.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>After final approval of the advertisement: No refunds will be issued once the advertisement has been finalized and approved by the celebrity and the agency.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>The refund amount will be processed within 30 days from the date of the contract termination notice. Upon completion of the refund, a separate notification will be sent to the advertiser.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>Non-Refundable Circumstances</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>If the advertisement has already been published</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>If the contract explicitly specifies non-refundable conditions</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>If refunds are restricted under Celevu's policies</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>d.</span>
						<span>Refund Fees</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Certain fees may apply depending on the payment method used for the refund, and these fees will be deducted from the refund amount.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>e.</span>
						<span>Refund Currency and Exchange Rate</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>The refund amount will be issued in the currency and payment method initially selected at the time of the contract fee payment. The refund amount will be calculated based on the exchange rate on the payment date, after deducting any applicable fees.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>9. Account Deletion</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Procedure</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>A user may request to delete their account from the Celevu service at any time. To initiate account deletion, the user may submit a withdrawal request directly through the "Account Deletion" menu within the platform. Upon receipt of the request, Celevu will promptly process the deletion.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Effects</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Account Deletion: Upon completion of the deletion process, the user’s account and related information will be deleted immediately. However, information that must be retained in accordance with applicable laws will be securely stored separately.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Termination of Service Use: After deletion, the user will no longer have access to the Celevu platform, and service records prior to deletion cannot be restored.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>Contracts and Payment Records: Rights and obligations related to contracts and payment records established prior to deletion will remain valid even after deletion. These matters will be governed by the relevant contracts or terms and conditions.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Rejoining</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Users may rejoin Celevu at any time. However, previous accounts and data will not be restored upon rejoining. Users wishing to rejoin must complete the registration process as new users.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>4.</span>
						<span>Data Retention</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>In accordance with applicable laws, Celevu may retain transaction records and payment details for a designated period following account deletion. During this retention period, the information will only be used for legal purposes, and it will be permanently deleted once the retention period expires.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>5.</span>
						<span>Important Considerations</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Account recovery is not possible after deletion, and services or benefits purchased or provided prior to deletion will no longer be accessible.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Users remain responsible for any ongoing contracts or payment obligations at the time of deletion, in accordance with the relevant contract and payment terms.</span>
					</p>
				</Section>
				<Section id="data-management-and-privacy" marginTop={80}>
					<MainSection>III. Data Management and Privacy</MainSection>
				</Section>
				<Section marginTop={40}>
					<SubSection>10. ColLegal Matterslection and Transmission of Information</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>The company may collect behavioral information from users (celebrities, agencies, and advertisers) during
						the use of the service to provide enhanced services. The information collected may include, but is not
						limited to, the following:</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Collection of Behavioral Information</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Access times and frequency</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Click and scroll patterns</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>(For advertisers) Viewing and visit records of celebrity pages</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>d.</span>
						<span>Details and history of contract creation</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>d.</span>
						<span>Details and history of contract creation</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>The collected behavioral information is securely stored on the company's servers, and Aligo implements reasonable technical and administrative measures to protect it. Except when required to comply with legal obligations, the collected information shall not be disclosed to third parties.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Transmission and Sharing of Information:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>The company may transmit or share the collected behavioral information only under the following circumstances:</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>When necessary for the execution of contracts with advertisers or partner companies</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>When used for internal analysis to improve the service</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>When required to provide information in response to legal requests in accordance with applicable laws</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>4.</span>
						<span>Consent to Information Provision</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Users must explicitly consent to the collection and transmission of behavioral information, and such consent is obtained through the acceptance of the terms and conditions provided during service use. Users may refuse or withdraw their consent to information collection at any time; however, this may result in certain restrictions on the use of some services.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>11. Rights and Obligations</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>The advertiser must consult with the agency in advance regarding all relevant matters during the contract process, while the agency and the celebrity must faithfully fulfill the obligations stipulated in the finalized contract.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>The advertiser shall not be held liable for unauthorized reproduction, distribution, or use of the campaign materials by third parties unless such actions result from the advertiser’s intentional misconduct or negligence</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>The agency and the celebrity shall not engage in any actions or statements during the contract period that could damage the reputation, credibility, or corporate image of the advertiser or the advertiser’s brand.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>4.</span>
						<span>The agency and the celebrity represent and warrant that there are no contractual relationships or legal or factual restrictions with third parties that could limit the execution or fulfillment of the contract.</span>
					</p>
				</Section>
				<Section id="legal-matters" marginTop={80}>
					<MainSection>IV. Legal Matters</MainSection>
				</Section>
				<Section marginTop={40}>
					<SubSection>12. Intellectual Property Rights</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>The advertiser shall own the intellectual property rights to the advertising materials created under contracts concluded through Celevu. The agency and the celebrity shall not claim any intellectual property rights to these materials. However, the advertiser is permitted to use the advertising materials only during the contract period, and their use is restricted after the contract's termination unless otherwise agreed in writing.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>13. Compensation for Damages</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>If one party breaches or fails to fulfill this contract, causing damage to the other party, the responsible party shall compensate for the resulting damages.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>14. Confidentiality</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>The parties to this contract shall not disclose or leak any information provided or disclosed under this contract to a third party without prior written consent from the other party.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>15. Application and Interpretation of the Contract</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>For matters not specified in these terms or where differences in interpretation arise, both parties shall resolve them amicably through mutual agreement in good faith. If an agreement cannot be reached, the matters shall be governed by applicable laws and established practices.</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>In the event of legal disputes related to these terms, the Seoul Central District Court shall have exclusive jurisdiction by mutual agreement.</span>
					</p>
				</Section>
				<Section marginTop={40}>
					<SubSection>16. Effectiveness and Termination of the Contract</SubSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>These terms shall take effect immediately upon registration.</span>
					</p>
				</Section>
				<Section id="marketing" marginTop={80}>
					<MainSection>[Marketing] Collection of User Information</MainSection>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>Keeping your personal information safe is one of our top priorities. Please review and confirm your consent below</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>1.</span>
						<span>Purpose of Collection and Use</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>All service notifications.</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>i.</span>
						<span>Contract requests</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>ii.</span>
						<span>Celevu web messaging tool inbox</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested roman">
						<span>iii.</span>
						<span>Profile view requests</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>2.</span>
						<span>Items</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>Email Address</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>b.</span>
						<span>Company</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>c.</span>
						<span>Position</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>d.</span>
						<span>Phone number</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>3.</span>
						<span>Retention Period</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>To be destroyed immediately after account closure or withdrawal of consent to the Terms of Service</span>
					</p>
				</Section>
				<Section marginTop={20}>
					<p>
						<span>4.</span>
						<span>Refusal</span>
					</p>
				</Section>
				<Section marginTop={2}>
					<p className="nested">
						<span>a.</span>
						<span>You can still sign up as a member without providing consent, but marketing-related benefits and information will not be offered</span>
					</p>
				</Section>
				{/*<Stack spacing={2} direction="row" alignItems="center">*/}
				{/*	<CircularProgress size="30px" />*/}
				{/*	<CircularProgress size={40} />*/}
				{/*	<CircularProgress size="3rem" />*/}
				{/*</Stack>*/}
			</Content>
		</Wrapper>
	);
};

export default TermsOfServicePage;
