import { useAtom } from "jotai";
import React, { useState } from "react";
import styled, {css} from "styled-components";
import {celebFilterAtom, displayCountryAtom, isApplyButtonAtom, tempSelectedCountryAtom} from "../../atoms/filter";

const ApplyButton = styled.button<{ $isClicked: boolean }>`
  width: 17.2rem;
  height: 4.4rem;
  flex-shrink: 0;
  //background: var(--gray-900);
  background-color: #AF6EFD;
  color: var(--gray-050);
  margin: 0 auto;
  display: block;
  transition: background 0.3s ease;

  &:hover {
    background: #9A47FB;
  }
`;

interface ApplyProps {
  refetch: () => void;
}

const Apply = ({ refetch }: ApplyProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [tempSelectedCountry] = useAtom(tempSelectedCountryAtom);
  const [, setDisplayCountry] = useAtom(displayCountryAtom);
  const [, setQuery] = useAtom(celebFilterAtom);

  const handleClick = () => {
    setIsClicked(true);
    setDisplayCountry(tempSelectedCountry);  // 실제 표시할 국가 업데이트
    setQuery(prev => ({
      ...prev,
      country: tempSelectedCountry
    }));
    refetch();
  };

  return (
    <ApplyButton onClick={handleClick} $isClicked={isClicked}>
      Apply
    </ApplyButton>
  );
};

export default Apply;
