import React, { useEffect } from "react";
import CelebListSidebar from "./CelebListSidebar";
import CelebCardList from "./CelebCardList";
import Filter from "./Filter";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import Layout from "../layout/Layout";
import { useAtom } from "jotai";
import {celebFilterAtom, displayCountryAtom, isApplyButtonAtom} from "../../atoms/filter";
import { useCelebList } from "../../hooks/useCeleb";
import {countryList} from "../../utils/utils";

const Wrapper = styled.div`
  display: flex;
  gap: 4rem;
  width: 100%;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 117.6rem;
`;

const CelebListMapWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Allows the elements to stack vertically */
  gap: 4px; /* Adjust spacing between the map and text */
`;

const CelebListMap = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: #7a7a7a;
  white-space: nowrap;

  img {
    width: 16px;
    height: 16px;
  }

  span {
    &:first-of-type {
      color: #b3b3b3; /* Lighter gray for "Worldwide" */
    }

    &:nth-of-type(2) {
      color: #b3b3b3; /* Lighter gray for the arrow */
    }

    &:last-of-type {
      color: #7A7A7A; /* Darker gray for the selected country */
    }
  }
`;

const CelebListMapContent = styled.div`
  color: var(--Grey-500, #A4A4A4);
  font-family: Inter, sans-serif;
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
  line-height: 120%;
`;

const CelebList = () => {
  const [query, setQuery] = useAtom<CelebListFilterQuery>(celebFilterAtom);
  const [displayCountry] = useAtom(displayCountryAtom);
  const { isPending, data, refetch } = useCelebList(query);

  const getCountryLabel = (value: string) => {
    const country = countryList.find((c) => c.value === value);
    return country ? country.label : value;
  };

  const updateArray = (target: string[], value: string, checked: boolean) => {
    return checked ? [...target, value] : target.filter((item) => item !== value);
  };

  const queryHandler = (
    name: keyof CelebListFilterQuery,
    value: string | number,
    checked?: boolean
  ) => {
    setQuery((prevQuery) => {
      const targetValue = prevQuery[name];

      if (checked !== undefined && typeof value === "string" && Array.isArray(targetValue)) {
        return {
          ...prevQuery,
          [name]: updateArray(targetValue as string[], value, checked),
        };
      }

      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    refetch();
  }, [query.gender, query.order__by]);

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <Layout>
      <Wrapper>
        <CelebListSidebar
          query={query}
          setQuery={queryHandler}
          refetch={refetch}
        />
        <MainSection>
          <Filter query={query} setQuery={queryHandler} />
          <CelebListMapWrapper>
            <CelebListMap>
              <img src={"/svg/celeblist/map.svg"} alt={"celebListMap"} />
              <span>Worldwide</span>
              {displayCountry && displayCountry !== "WW" && (
                <>
                  <span>&gt;</span>
                  <span>{getCountryLabel(displayCountry)}</span>
                </>
              )}
            </CelebListMap>
            <CelebListMapContent>
              The Followers and Search data are projected figures for the respective country.
            </CelebListMapContent>
          </CelebListMapWrapper>
          <CelebCardList celebList={data.data}/>
        </MainSection>
      </Wrapper>
    </Layout>
  );
};

export default CelebList;
