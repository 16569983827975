import { getDefaultStore } from "jotai";
import { accessTokenAtom, refreshTokenAtom } from "../atoms/auth";
import { LoginFormData } from "../components/interfaces/auth.interfaces";
import {getEnv, privateClient, publicClient} from "./axiosInstance";

const store = getDefaultStore();

export const getAccessToken = () => store.get(accessTokenAtom);
export const getRefreshToken = () => store.get(refreshTokenAtom);

export const requestRefresh = async (refreshToken: string) => {
  const response = await publicClient.post(
    `${getEnv()}api/v1/users/refresh`,
    {
      refresh: refreshToken,
    },
    {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }
  );

  return {
    accessToken: response.data.accessToken,
    refreshToken: response.data.refreshToken,
  };
};

export const postLogin = async (data: LoginFormData) => {
  const response = await publicClient.post("/api/v1/users/login", data);
  return response.data;
};

export const getProfile = async () => {
  const response = await privateClient.get("/api/v1/users/profile");
  return response.data;
};

export const postVerifyEmail = async (email: string) => {
  const response = await publicClient.post("/api/v1/email/send", {
    email: email,
  });

  return response.data;
};

export const getVerifyStatus = async (email: string) => {
  const response = await publicClient.get(
    "/api/v1/email/verification-front?email=" + email
  );

  return response.data;
};

export const updateNewUserPassword = async (
  token: string,
  password: string,
  confirmPassword: string,
) => {
  const body = {
    token,
    password,
    confirmPassword,
  }
  console.log('body', body)
  return await publicClient.put("/api/v1/users/reset-password", body);
}
