import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ProjectContent from "./components/dashboard/ProjectContent";
import Contract from "./components/dashboard/Contract";
import Favorite from "./components/dashboard/advertiser/Favorite";
import RouteProtect from "./pages/RouteProtect";
import ProjectDetail from "./components/dashboard/ProjectDetail";
import ContractDetail from "./components/dashboard/advertiser/ContractDetail";
import CreateProject from "./components/advertiser/CreateProject";
import CelebContent from "./components/dashboard/celebrityAgency/CelebContent";
import MyCelebList from "./components/dashboard/celebrityAgency/MyCelebList";
import ContractListForCelebAgency from "./components/dashboard/celebrityAgency/ContractListForCelebAgency";
import ProjectListForAdvertiser from "./components/dashboard/advertiser/ProjectListForAdvertiser";
import CelebListContent from "./components/dashboard/advertiser/CelebListContent";
import ContractHistoryPage from "./pages/ContractHistoryPage";
import HomePage from "./pages/HomePage";
import CelebList from "./components/celeb/CelebList";
import Celeb from "./components/celeb/Celeb";
import CelebsPage from "./pages/CelebsPage";
import ContractLayout from "./components/celeb/contract/ContractLayout";
import SelectType from "./components/celeb/contract/SelectType";
import SelectProject from "./components/celeb/contract/SelectProject";
import ContractForm from "./components/celeb/contract/ContractForm";
import ContractReview from "./components/celeb/contract/ContractReview";
import ContractComplete from "./components/celeb/contract/ContractComplete";
import SignupLayout from "./components/auth/Signup/SignupLayout";
import AgencyType from "./components/auth/Signup/AgencyType";
import Agreement from "./components/auth/Signup/Agreement";
import SignupForm from "./components/auth/Signup/SignupForm";
import { useAtomValue } from "jotai";
import { userDataAtom } from "./atoms/auth";
import PrivacyTermsPage from "./pages/PrivacyTermsPage";
import ContactUsPage from "./pages/ContactUsPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

function App() {
  // const { user, isPending } = useAuth();

  const user = useAtomValue(userDataAtom);
  // if (user === undefined || user === null) {
  //   return <div>Loading...</div>; // 초기 로딩 상태 처리
  // }

  const advertiserRoutes = (
    <>
      <Route path="projects" element={<ProjectContent />}>
        <Route index element={<Navigate to="list?type=ongoing" replace />} />
        <Route path="list" element={<ProjectListForAdvertiser />} />
        <Route path="create" element={<CreateProject />} />
        <Route path="detail" element={<ProjectDetail />} />
        <Route path="contract" element={<ContractDetail />}></Route>
      </Route>
      <Route path="celebs" element={<CelebListContent />}>
        <Route path="contract" element={<Contract />} />
        <Route path="favorite" element={<Favorite />} />
      </Route>
    </>
  );

  const celebrityAgnecyRoutes = (
    <>
      <Route path="celebs" element={<CelebContent />}>
        <Route index element={<Navigate to="list?type=active" replace />} />
        <Route path="list" element={<ContractListForCelebAgency />} />
        <Route path="contract" element={<ContractDetail />}></Route>
        <Route path="my-celebs" element={<MyCelebList />} />
      </Route>
    </>
  );

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/terms-of-service" element={<PrivacyTermsPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/signup" element={<SignupLayout />}>
          <Route index element={<Navigate to="agencytype" replace />} />
          <Route path="agencytype" element={<AgencyType />} />
          <Route path="agreement" element={<Agreement />} />
          <Route path="detail" element={<SignupForm />} />
        </Route>
        <Route
          path="/dashboard"
          element={
            <RouteProtect>
              <DashboardPage />
            </RouteProtect>
          }
        >
          {user?.companyType === "advertiser" && (
            <>
              <Route index element={<Navigate to="projects/" replace />} />
              {advertiserRoutes}
            </>
          )}
          {user?.companyType === "celebrity_agency" && (
            <>
              <Route index element={<Navigate to="celebs/" replace />} />
              {celebrityAgnecyRoutes}
            </>
          )}
        </Route>
        <Route
          path="/celebs"
          element={
            <RouteProtect>
              <CelebsPage />
            </RouteProtect>
          }
        >
          {user?.companyType === "advertiser" && (
            <>
              <Route index element={<CelebList />} />
              <Route path=":id" element={<Celeb />} />
              <Route
                path="/celebs/:celebId/contract/"
                element={<ContractLayout />}
              >
                <Route index element={<SelectType />} />
                <Route path="project" element={<SelectProject />} />
                <Route path="project/create" element={<CreateProject />} />
                <Route path="detail" element={<ContractForm />} />
                <Route path="review" element={<ContractReview />} />
                <Route path="complete" element={<ContractComplete />} />
              </Route>
            </>
          )}
        </Route>

        <Route
          path="/history/:id"
          element={
            <RouteProtect>
              <ContractHistoryPage />
            </RouteProtect>
          }
        />
        {/* <Route path="/celebs/" element={<CelebListPage />} />
        <Route path="/celebs/:id" element={<CelebPage />} />
        <Route path="/celebs/:id/contract" element={<RequestContractPage />} /> */}
        {/*<Route
          path="*"
          element={
            <Navigate
              to={
                userType === "ADVERTISER"
                  ? "/"
                  : "/"
              }
              replace
            />
          }
        />*/}
        {/*</Route>*/}
      </Routes>
    </div>
  );
}

export default App;
