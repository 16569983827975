import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme } from "./styles/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Inter', sans-serif;
    font-size: 62.5%;
    --white: #FFF;
    --black: #000;
    --gray-900: #252525;
    --gray-800: #474747;
    --gray-700: #666666;
    --gray-600: #7A7A7A;
    --gray-500: #A4A4A4;
    --gray-400: #C2C2C2;
    --gray-300: #E5E5E5;
    --gray-200: #F1F1F1;
    --gray-100: #F6F6F6;
    --gray-050: #FBFBFB;
    
    --purple-900: #3800E0;
    --purple-800: #5900E5;
    --purple-700: #6A00EA;
    --purple-600: #7B01F2;
    --purple-500: #860CF9;
    --purple-400: #9B47FC;
    --purple-300: #AF6EFD;
    --purple-200: #C79BFD;
    --purple-100: #DDC4FD;
    --purple-050: #F3E7FE;

    --crimson-600: #DB0A5B;
    --crimson-100: #FABACE;
    --crimson-050: #FDE4EB;
    --blue-500: #008DFF;

    --primary: var(--purple-300);
    --font-primary: var(--gray-900);
  }
  
  a {
    color: var(--font-primary);
    text-decoration: none;
  }
  
  body {
    font-size: 1.6rem;
    color: var(--font-primary);
    margin: 0;
    padding: 0;
    font-style: normal;
    font-variant: normal;
    // css 에 삽입하는 방법
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
  }

  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip-path: inset(0 0 0 0);
    border: none;
  }

  input[type='file'] + label {
    cursor: pointer;
  }

  ul,ol,li {
    list-style: none outside;
  }
  
  input {
    outline: none;
  }
`;
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
