import React from "react";
import styled from "styled-components";
import { CelebListData } from "../interfaces/celeb.interfaces";
import CelebCard from "./celebCard/CelebCard";

const CelebCardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.6rem;
`;

interface CelebCardListProps {
  celebList: CelebListData[];
}

const CelebCardList = ({ celebList }: CelebCardListProps) => {
  return (
    <CelebCardListContainer>
      {celebList?.length > 0 ? (
        celebList?.map((data, index) => {
          return <CelebCard key={data.id + index} celebData={data} />;
        })
      ) : (
        <div>No matching celebrities found</div>
      )}
    </CelebCardListContainer>
  );
};

export default CelebCardList;
