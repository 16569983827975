import React from "react";
import styled from "styled-components";
import LockIcon from "@mui/icons-material/Lock";

const Overlay = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
	justify-content: center;
	align-items: center;
`;

const ModalContainer = styled.div`
	width: 400px;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 24px;
	text-align: center;
	position: relative;
`;

const IconContainer = styled.div`
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fde4eb;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 1px solid #db0a5b;

	svg {
		color: #db0a5b;
		font-size: 24px;
	}
`;

const Title = styled.h2`
	margin-top: 8px;
	font-size: 20px;
	font-weight: 700;
	color: #252525;
	margin-bottom: 28px;
`;

const Content = styled.p`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	color: #252525;
	margin-bottom: 40px;

	span {
		font-weight: 700;
		font-style: italic;
		color: #252525;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const Button = styled.button<{ primary?: boolean }>`
	flex: 1;
	height: 60px;
	border: 1px solid ${({ primary }) => (primary ? "#252525" : "#cccccc")};
	border-radius: 4px;
	background-color: ${({ primary }) => (primary ? "#252525" : "#ffffff")};
	color: ${({ primary }) => (primary ? "#ffffff" : "#252525")};
	font-size: 16px;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: ${({ primary }) =>
			primary ? "#444444" : "#f5f5f5"};
	}
`;

interface IPermissionRequestModalsProps {
	isVisible: boolean;
	onClose: (event: React.MouseEvent) => void;
	onSubmit: (event: React.MouseEvent) => void;
	isLoading?: boolean; // 로딩 상태 prop 추가
}

const LimitedPermissionRequestModals: React.FC<IPermissionRequestModalsProps> = ({
	                                                                          isVisible,
	                                                                          onClose,
	                                                                          onSubmit,
	                                                                          isLoading = false,
                                                                          }) => {
	return (
		<Overlay isVisible={isVisible} onClick={onClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()}>
				<IconContainer>
					<LockIcon />
				</IconContainer>
				<Title>Permission Request</Title>
				<Content>
					Do you want to submit a request for further details of this celebrity?
				</Content>
				<ButtonContainer>
					<Button onClick={onClose} disabled={isLoading}>Cancel</Button>
					<Button
						primary
						onClick={onSubmit}
						disabled={isLoading}
					>
						{isLoading ? "Submitting..." : "Submit"}
					</Button>
				</ButtonContainer>
			</ModalContainer>
		</Overlay>
	);
};

export default LimitedPermissionRequestModals;
