import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMutation } from "@tanstack/react-query";
import {
  DataErrorResponse,
  InputValidationErrorResponse,
  LoginFormData,
  LoginSuccessResponse,
} from "../interfaces/auth.interfaces";
import { updateTokens, userDataAtom } from "../../atoms/auth";
import { useNavigate } from "react-router-dom";
import { postLogin } from "../../api/auth";
import { AxiosError } from "axios";
import Logo from "../layout/Logo";
import { useAtom } from "jotai";
import CheckButton from "../button/CheckButton";
import PasswordInput from "../input/PasswordInput";
import RegisterInput from "../input/RegisterInput";


const LoginContainer = styled.div`
  width: 42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  > div:first-child {
    margin-bottom: 2.4rem;
  }
  .submit-button {
    display: flex;
    height: 4.8rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 3rem;
    color: var(--gray-050);
    margin-top: 3.2rem;
    background-color: var(--purple-300);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
  }
`;

const LoginError = styled.div<{ $error: boolean }>`
  display: flex;
  justify-content: center;
  padding: 8px 20px;
  align-items: center;
  width: 42rem;
  //height: 4rem;
  border-radius: 0.4rem;
  ${({theme}) => theme.font.body1};
  ${({theme}) => theme.font.legular};
  margin: 2rem auto;
  //text-align: center;
  background-color: var(--crimson-050);
  color: var(--crimson-600);
  gap: 0.8rem;
  visibility: ${({$error}) => ($error ? "visible" : "hidden")};
`;

const RememberIdAndForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2rem;
`;

const RememberId = styled.div<{ $remember: boolean }>`
  color: var(--gray-600);

  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  .button-wrapper {
    cursor: pointer;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    color: ${({ $remember }) =>
      $remember ? "var(--purple-300)" : "var(--gray-500)"};
  }
`;

const ForgotPassword = styled.div`
  cursor: pointer;
  align-content: center;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.underline};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
  a {
    cursor: pointer;
    ${({ theme }) => theme.font.underline};
  }
  .sign-up {
    margin-top: 2.8rem;
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    b {
      ${({ theme }) => theme.font.bold};
    }
  }
  .privacy-policy {
    margin-top: 1.2rem;
    color: var(--gray-600);
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--grey-grey600, #7a7a7a);
  }
`;

const schema = z.object({
  email: z
  .string()
  .min(1, { message: "required" })
  .email({ message: "Please enter a valid email address" }),
  password: z.string().min(1, { message: "required" }),
});

const Login = () => {
  const [remember, setRemember] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setUserData] = useAtom(userDataAtom);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });
  const navigate = useNavigate();

  const moveForgotPassword = () => {
    navigate("/reset-password");
  }

  const mutation = useMutation<
    LoginSuccessResponse,
    AxiosError<DataErrorResponse | InputValidationErrorResponse>,
    LoginFormData
  >({
    mutationFn: postLogin,
    onSuccess: (data) => {
      updateTokens(data.data.accessToken, data.data.refreshToken);
      if (remember) {
        rememberId(data.data.user.email);
      }
      setErrorMessage(""); // 에러 메시지 초기화
      setUserData(data.data.user);
      if (data.data.user.companyType === "advertiser") {
        navigate("/celebs");
      } else if (data.data.user.companyType === "celebrity_agency") {
        navigate("/dashboard");
      }
    },
    onError: (error) => {
      const responseError = error.response?.data as DataErrorResponse;
      console.log('responseError', responseError);
      setErrorMessage(responseError?.message || "An unknown error occurred.");
    },
  });

  const onSubmit = (data: LoginFormData) => {
    mutation.mutate(data);
  };

  useEffect(() => {
    const beforeStatus = localStorage.getItem("userEmail");
    if (beforeStatus) {
      setRemember(true);
      const rememberedEmail = localStorage.getItem("userEmail");
      if (rememberedEmail) {
        setValue("email", rememberedEmail);
      }
    } else {
      setRemember(false);
    }
  }, [setValue]);

  const rememberId = (email: string) => {
    localStorage.setItem("userEmail", email);
  };

  const onClickRemember = () => {
    if (remember === false) {
      setRemember(true);
      return;
    }
    localStorage.removeItem("userEmail");
    setRemember(false);
    return;
  };

  return (
    <LoginContainer>
      <Logo margin="6rem auto 0" url="/" />
      <LoginError $error={!!errorMessage}>
        <ErrorOutlineIcon />
        {errorMessage}
      </LoginError>
      <LoginFormContainer onSubmit={handleSubmit(onSubmit)}>
        <RegisterInput
          label="Email"
          fieldName="email"
          type="text"
          placeholder="Email"
          register={register}
          errorMessage={errors.email?.message}
          required={true}
        />
        <PasswordInput
          label="Password"
          fieldName="password"
          placeholder="password"
          register={register}
          errorMessage={errors.password?.message}
          required={true}
        />
        <RememberIdAndForgotPasswordContainer>
          <RememberId $remember={remember}>
            <span className="button-wrapper" onClick={() => onClickRemember()}>
              <CheckButton
                status={remember}
                setStatus={() => setRemember(!remember)}
                size={20}
              />
              Remember ID
            </span>
          </RememberId>
          <ForgotPassword onClick={moveForgotPassword}>Forgot Password</ForgotPassword>
        </RememberIdAndForgotPasswordContainer>
        <button
          className="submit-button"
          type="submit"
          disabled={mutation.isPending}
        >
          Log in
        </button>
        <Description>
          <p className="sign-up">
            Don't have an account?{" "}
            <a href="/signup">
              <b>Sign up</b>
            </a>
          </p>
          <p className="privacy-policy">
            By creating an account you agree with our {"\n"}{" "}
            <a href="/terms-of-service">Privacy & Terms of Use</a>{" "}
            {/*<a href="/privacy-policy">Privacy Policy</a>.*/}
          </p>
        </Description>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Login;
