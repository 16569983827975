// ContactUsInput.tsx
import { ChangeEvent } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`;

const Label = styled.label`
	font-family: Inter, serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 19.2px */
	
  color: #666;
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 12px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #9747FF;
  }

  &::placeholder {
		font-style: normal;
    color: #999;
  }
`;

interface ContactUsInputProps {
	label: string;
	type?: string;
	placeholder?: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ContactUsInput = ({
	                        label,
	                        type = "text",
	                        placeholder,
	                        value,
	                        onChange,
                        }: ContactUsInputProps) => {
	return (
		<Wrapper>
			<Label>{label}</Label>
			<Input
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
		</Wrapper>
	);
};

export default ContactUsInput;
