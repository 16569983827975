import styled from "styled-components";

export const CelebCardWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1.6rem;
  width: 58rem;
  height: 30rem;
  padding: 2rem;
  border-radius: 0.8rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(71, 71, 71, 0.1);
  }
`;

export const FavoriteWrapper = styled.div`
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
`;

export const CelebSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CelebCardHoverContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const ClosedTooltip = styled.div`
    min-width: 240px;
    max-width: 300px;
    word-wrap: break-word;
    overflow: visible;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #333;
    padding: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #FF6F00;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    line-height: 1.4;
    transition: opacity 0.2s, transform 0.2s;
    z-index: 10;

    // 오렌지색 외부 삼각형
    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top-color: #FF6F00;
        border-bottom: 0;
        margin-top: 0;
        z-index: 1;
    }
    // 흰색 내부 삼각형
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 11px solid transparent;
        border-top-color: white;
        border-bottom: 0;
        margin-top: -1px;
        z-index: 2;
    }
    
    .title {
        font-weight: bold;
        width: 200px;
        gap: 4px;
        height: 17px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;

        img {
            margin-right: 0.4rem;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content {
        margin-top: 0.4rem;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        left: auto;
        right: 0;
        transform: translateX(0);
    }
`;

export const LimitedTooltip = styled.div`
    min-width: 240px;
    max-width: 300px;
    word-wrap: break-word;
    overflow: visible;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #333;
    padding: 1rem;
    border-radius: 0.4rem;
    border: 1px solid #DB0A5B;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 1.2rem;
    line-height: 1.4;
    transition: opacity 0.2s, transform 0.2s;
    z-index: 10;

    // 오렌지색 외부 삼각형
    &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-top-color: #DB0A5B;
        border-bottom: 0;
        margin-top: 0;
        z-index: 1;
    }
    // 흰색 내부 삼각형
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 11px solid transparent;
        border-top-color: white;
        border-bottom: 0;
        margin-top: -1px;
        z-index: 2;
    }
    
    .title {
        font-weight: bold;
        width: 200px;
        gap: 4px;
        height: 17px;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;

        img {
            margin-right: 0.4rem;
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .content {
        margin-top: 0.4rem;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        left: auto;
        right: 0;
        transform: translateX(0);
    }
`;

export const CelebCardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const CelebInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .name {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
    color: var(--gray-900);
  }
  .age {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  .type {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

export const CelebScoreList = styled.div`
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body1};
  color: var(--gray-900);
`;

export const CelebScore = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  .name {
    ${({ theme }) => theme.font.bold};
  }
  .number {
    ${({ theme }) => theme.font.regular};
  }
`;
