import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import { countryList } from "../../utils/utils";
import {useAtom} from "jotai/index";
import {celebCountryFilterAtom, celebFilterAtom, isApplyButtonAtom, tempSelectedCountryAtom} from "../../atoms/filter";

const CountrySelect = styled.select`
  width: 25.6rem;
  height: 4rem;
  padding: 1rem 2rem;
  border: 0.1rem solid var(--purple-300);
  border-radius: 2rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

interface CountryProps {
  query: CelebListFilterQuery;
  setQuery: Function;
}

const Country = ({ query, setQuery }: CountryProps) => {
  const [selectedCountry, setSelectedCountry] = useAtom<string>(celebCountryFilterAtom);
  const [, setTempSelectedCountry] = useAtom(tempSelectedCountryAtom);

  const FilterCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setQuery("country", value);
    const foundCountryLabel = countryList.find((country) => country.value === e.target.value)?.label;
    setSelectedCountry(foundCountryLabel || "");
    setTempSelectedCountry(value);  // 임시 상태에만 저장
  }
  return (
    <CountrySelect
      id="country-select"
      name="country"
      value={query.country}
      onChange={FilterCountry}
    >
      {countryList.map((country) => {
        return (
          <option key={"select_option_" + country.id} value={country.value}>
            {country.label}
          </option>
        );
      })}
    </CountrySelect>
  );
};

export default Country;
