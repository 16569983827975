export const colors = {
  primary: {
    50: '#F3E7FE',
    100: '#DDC4FD',
    200: '#C79BFD',
    300: '#AF6EFD', // Primary
    400: '#9B47FC',
    500: '#860CF9',
    600: '#7B01F2',
    700: '#6A00EA',
    800: '#5900E5',
    900: '#3800E0',
  },
  neutral: {
    50: '#FBFBFB',
    100: '#F6F6F6',
    200: '#F1F1F1',
    300: '#E5E5E5',
    400: '#C2C2C2',
    500: '#A4A4A4',
    600: '#7A7A7A',
    700: '#666666',
    800: '#474747',
    900: '#252525', // Logo
    black: '#000000',
  },
  aligo: {
    50: '#FDE4EB',
    100: '#FABACE',
    200: '#F78DAE',
    300: '#F35F8D',
    400: '#EF3A74',
    500: '#EC0E5D',
    600: '#DB0A5B', // Aligo main
    700: '#C50656',
    800: '#B10052',
    900: '#8C004C',
  },
  alert: {
    success: '#4CAF50',
    warning: '#FF9800',
    error: '#F44336',
  },
  main: {
    bora: '#AF6EFD', // Primary color
    black: '#252525',
  },
};
