// ContactUsMessageInput.tsx
import styled from "styled-components";
import {ChangeEvent} from "react";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`;

const Label = styled.label`
	font-family: Inter, serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 19.2px */

	color: #666;
	margin-bottom: 8px;
`;

const TextArea = styled.textarea`
  padding: 12px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  min-height: 120px;
	resize: none;
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #9747FF;
  }

  &::placeholder {
		font-style: normal;
    color: #999;
  }
`;

// ContactUsMessageInput.tsx
interface ContactUsMessageInputProps {
	label: string;
	placeholder?: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ContactUsMessageInput = ({
	                               label,
	                               placeholder,
	                               value,
	                               onChange,
                               }: ContactUsMessageInputProps) => {
	return (
		<Wrapper>
			<Label>{label}</Label>
			<TextArea
				placeholder={placeholder}
				value={value}
				onChange={onChange} // 직접 이벤트 객체를 전달
			/>
		</Wrapper>
	);
};

export default ContactUsMessageInput;
