import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	width: 576px;
	height: 400px;
	padding: 80px 84px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	
	border-radius: 8px;
	border: 1px solid var(--Secondary-Secondary-100, #DDC4FD);
	background: var(--Uncategorized-White, #FFF);
`;

const ContentWrapper = styled.div`
	display: flex;
	width: 372px;
	height: 224px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;

const Title = styled.h2`
	font-size: 24px;
	font-weight: 500;
	margin-top: 8px;
	color: #252525;
`;

const Message = styled.p`
  font-size: 16px;
  color: #555;
	margin-bottom: 40px;
`;

interface IModalProps {
	img?: string; // Optional icon or image
	title: string;
	message: string;
}

const ContactUsReceived = ({ img, title, message }: IModalProps) => {
	return (
		<Wrapper>
			<ContentWrapper>
				<img src={img}/>
				<Title>{title}</Title>
				<Message>{message}</Message>
			</ContentWrapper>
		</Wrapper>
	);
};

export default ContactUsReceived;
