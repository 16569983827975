import React from 'react';
import styled from "styled-components";
import PendingIcon from '@mui/icons-material/Pending';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';

const Overlay = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(2px);
	justify-content: center;
	align-items: center;
`;

const ModalContainer = styled.div`
	width: 400px;
	background: #fff;
	border-radius: 8px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	padding: 24px;
	text-align: center;
	position: relative;
`;

const IconContainer = styled.div<{ status: 'pending' | 'success' | 'rejected' }>`
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px; // Adjust size for better match
	height: 48px; // Adjust size for better match
	border-radius: 50%;
	background: #FFEBEE;
	border: 2px solid ${({ status }) => {
		if (status === 'rejected') return '#F44336';
		if (status === 'pending') return '#4CAF50';
		if (status === 'success') return '#E0F7FA';
	}};
	background: ${({ status }) => {
		if (status === 'rejected') return '#FFEBEE';
		if (status === 'pending') return '#E8F5E9';
		if (status === 'success') return '#E0F7FA';
	}};

	svg {
		font-size: 32px; // Adjust icon size
		color: ${({ status }) => {
			if (status === 'rejected') return '#F44336';
			if (status === 'pending') return '#4CAF50';
			if (status === 'success') return '#00ACC1';
		}};
	}
`;

const Title = styled.h2`
	margin-top: 8px;
	font-size: 20px;
	font-weight: 700;
	color: #252525;
	margin-bottom: 28px;
	white-space: pre-line;
`;

const ButtonContainer = styled.div`
	margin-top: 40px;
	gap: 16px;
`;

const Button = styled.button<{ primary?: boolean }>`
	flex: 1;
	width: 160px;
	height: 60px;
	border: 1px solid ${({ primary }) => (primary ? "#252525" : "#cccccc")};
	border-radius: 2px;
	background-color: ${({ primary }) => (primary ? "#252525" : "#ffffff")};
	color: ${({ primary }) => (primary ? "#ffffff" : "#252525")};
	font-size: 16px;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		background-color: ${({ primary }) =>
	primary ? "#444444" : "#f5f5f5"};
	}
`;

interface IPermissionPendingSuccessModalsProps {
	isVisible: boolean;
	titleMessage: string;
	status: 'pending' | 'success' | 'rejected';
	onClose: (event: React.MouseEvent) => void;
}

const PermissionPendingSuccessModals: React.FC<IPermissionPendingSuccessModalsProps> = ({
	                                                                                        isVisible,
	                                                                                        titleMessage,
	                                                                                        status,
	                                                                                        onClose
                                                                                        }) => {
	const iconStatus = () => {
		if (status === 'pending') {
			return <PendingIcon />;
		} else if (status === 'success') {
			return <CheckIcon />;
		}
	};

	return (
		<Overlay isVisible={isVisible} onClick={onClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()}>
				{
					status === 'rejected' ? (
						<>
							<img src={"image/do_not_disturb_on.png"}/>
						</>
					) : (
						<IconContainer status={status}>
							{iconStatus()}
						</IconContainer>
					)
				}
				<Title>{titleMessage}</Title>
				<ButtonContainer>
					<Button primary onClick={onClose}>OK</Button>
				</ButtonContainer>
			</ModalContainer>
		</Overlay>
	);
};

export default PermissionPendingSuccessModals;
