import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import StyledButton from "../../button/StyledButton";
import { userType } from "../../interfaces/auth.interfaces";
import RegisterInput from "../../input/RegisterInput";
import PasswordInput, {ConfirmPasswordInput} from "../../input/PasswordInput";
import EmailVerifyInput from "../../input/EmailVerifyInput";
import { publicClient } from "../../../api/axiosInstance";
import { useSignupMutation } from "../../../hooks/useSignup";
import { useAtom } from "jotai";
import { signupAtom } from "../../../atoms/auth";
import { StepDescription } from "../../common/FormContainer";
import Modals from "../../modals/Modals";
import {AxiosError} from "axios";

const schema = z
.object({
  email: z.string().email({ message: "Please enter a valid email address." }),
  password: z
  .string()
  .min(8, { message: "Password must meet the required format." })
  .regex(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/, { message: "Password must meet the required format." }),
  passwordConfirm: z
  .string()
  .min(8, { message: "Password must meet the required format." }),
  phone: z
  .string()
  .min(10, { message: "Please enter a valid phone number." })
  .regex(/^\d+$/, { message: "Please enter a valid phone number." }),
  companyName: z
  .string()
  .min(1, { message: "Please enter a valid company name." }),
  position: z.string().min(1, { message: "Please enter a valid position." }),
})
.refine((data) => data.password === data.passwordConfirm, {
  message: "Passwords do not match.",
  path: ["passwordConfirm"],
});

export interface SignupFormData {
  companyName: string;
  companyType: userType;
  position: string;
  agree: boolean;
  marketAgree: boolean;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
}

const SignupFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const Verify = styled.button<{ $isVerify: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  color: var(--gray-600);
  border-radius: 15px;
  padding: 0.6rem 1.2rem;
  border: 0.1rem solid var(--gray-300);
  background-color: var(--gray-050);
  ${({ $isVerify }) =>
    $isVerify ? "background-color: var(--purple-300)" : ""};
  ${({ $isVerify }) => ($isVerify ? "color: var(--white)" : "")};
`;

const SignupForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const methods = useForm<SignupFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = methods;

  const [emailToVerify, setEmailToVerify] = useState<string | null>(null);
  const [isSendEmailVerification, setIsSendEmailVerification] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [signupFormData] = useAtom(signupAtom);

  const email = watch("email");

  const postVerifyEmail = async (email: string) => {
    try {
      if (!isVerified) {
        await publicClient.post("/api/v1/email/send", { email });
        setModalMessage("We have sent a verification email. Please check your email.");
        setShowModal(true);
      }
      setIsSendEmailVerification(true);
      setEmailToVerify(email);
    } catch (error) {
      if (error instanceof  AxiosError && error.response) {
        const { data } = error.response;

        setError("email", {
          type: "custom",
          message: data.message,
        });
        // 줄바꿈 처리를 위한 메시지 변환
        const formattedMessage = data.message.replace(/\n/g, "<br/>");
        const addEmailWithFormattedMessage = `${formattedMessage}<br/><br/>`;

        setModalMessage(addEmailWithFormattedMessage);
        setShowModal(true);
      } else {
        console.error("Unexpected error:", error);
        setError("email", {
          type: "custom",
          message: "An unexpected error occurred. Try again later.",
        });
      }
    }
  };

  const getVerifyStatus = async (email: string) => {
    try {
      if (isVerified) {
        return;
      }
      const response = await publicClient.get(
        `/api/v1/email/verification-front?email=${email}`
      );
      if (response.data.ok) {
        setIsVerified(true);
      }
    } catch (error) {}
  };

  const beforeHandleVerify = async (email: string) => {
    if (!email) {
      setError("email", { type: "custom", message: "Email is required." });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("email", { type: "custom", message: "Invalid email format." });
      return;
    }
    await postVerifyEmail(email);
  };

  useEffect(() => {
    const handleMouseClick = () => {
      if (emailToVerify && !isVerified) {
        getVerifyStatus(emailToVerify);
      }
    };

    if (!isVerified) {
      document.addEventListener("click", handleMouseClick);
      return () => {
        document.removeEventListener("click", handleMouseClick);
      };
    }
  }, [emailToVerify, isVerified]);

  const signupMutation = useSignupMutation(setError);

  const onSubmit = (data: SignupFormData) => {
    if (!isVerified) {
      setError("email", {
        type: "custom",
        message: "Please verify your email first.",
      });
      return;
    }

    const formData = {
      ...data,
      companyType: signupFormData.companyType,
      agree: true,
      marketAgree: signupFormData.marketAgree,
    };
    signupMutation.mutate(formData);
  };

  if (!signupFormData.companyType) {
    return <Navigate to="../" />;
  }

  return (
    <>
      <StepDescription step={3} length={4}>
        Email verification are required.
      </StepDescription>
      <FormProvider {...methods}>
        <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
          <EmailVerifyInput
            label="Email"
            fieldName="email"
            placeholder="Email"
            register={register}
            errorMessage={errors.email?.message}
            required={true}
            disabled={isVerified}
          >
            <Verify
              type="button"
              onClick={() => beforeHandleVerify(email)}
              $isVerify={isVerified}
            >
              {isVerified
                ? "Verified"
                : isSendEmailVerification
                  ? "Sent"
                  : "Verify"}
            </Verify>
          </EmailVerifyInput>
          <PasswordInput
            label="Password"
            fieldName="password"
            placeholder="Password must meet the required format."
            register={register}
            errorMessage={errors.password?.message}
            required={true}
          />
          <ConfirmPasswordInput
            label="Confirm Password"
            fieldName="passwordConfirm"
            placeholder="Confirm password"
            register={register}
            errorMessage={errors.passwordConfirm?.message}
            required={true}
          />
          <RegisterInput
            label="Phone number"
            fieldName="phone"
            type="text"
            placeholder="Phone number"
            register={register}
            errorMessage={errors.phone?.message}
            required={true}
          />
          <RegisterInput
            label="Company name"
            fieldName="companyName"
            type="text"
            placeholder="Company name"
            register={register}
            errorMessage={errors.companyName?.message}
            required={true}
          />
          <RegisterInput
            label="Position"
            fieldName="position"
            type="text"
            placeholder="Position"
            register={register}
            errorMessage={errors.position?.message}
            required={true}
          />
          <StyledButton type="submit" label="Continue" />
        </SignupFormContainer>
      </FormProvider>
      {showModal && (
        <Modals
          message={modalMessage}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default SignupForm;
