import React from "react";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import {ECelebType} from "./celebCard/CelebCard";

const Wrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const ImageListContainer = styled.div`
  width: 10.5rem;
  height: 76rem;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  li {
    width: 10.5rem;
    height: 12rem;
    border-radius: 0.4rem;
    overflow: hidden;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const FocusImageContainer = styled.div`
  background-color: var(--white);
  width: 96rem;
  height: 76rem;
  border-radius: 0.4rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

interface ImageViewProps {
  celebData: CelebData;
  selectedIndex: number;
  setSelectedIndex: any;
}
const ImageView = ({
  celebData,
  selectedIndex,
  setSelectedIndex,
}: ImageViewProps) => {
  const photos = celebData.celebStockData;
  const celebType = celebData.celebInfo.type;

  if (photos.length === 0) {
    return <></>;
  }

  const getFirstPhoto = photos[0];

  return (
    <Wrapper>
      {
        celebType === ECelebType.CLOSED ? (
          <FocusImageContainer>
            <img src={photos[selectedIndex].imgUrl} alt="selected_image" />
          </FocusImageContainer>
        ) : (
          <>
            <ImageListContainer>
              <ImageList>
                {photos.map((photo, index) => {
                  return (
                    <li
                      key={"celeb_image " + photo.photoId}
                      onClick={() => setSelectedIndex(index)}
                    >
                      <img
                        src={photo.imgUrl}
                        alt={`${celebData.celebInfo.name}_image`}
                      />
                    </li>
                  );
                })}
              </ImageList>
            </ImageListContainer>
            <FocusImageContainer>
              <img src={photos[selectedIndex].imgUrl} alt="selected_image" />
            </FocusImageContainer>
          </>
        )
      }
    </Wrapper>
  );
};

export default ImageView;
