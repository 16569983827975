import React from "react";
import {
  CardData,
  CardTitle,
  CountryColorSet,
  GridCard,
  ReportTitle,
} from "./ReportSection";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import AudienceGeoChart from "./AudienceGeoChart";
import { formatNumberWithCommas } from "../../utils/utils";
import HelpMessage from "../icon/HelpMessage";
import {useAtom} from "jotai/index";
import {celebCountryFilterAtom} from "../../atoms/filter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15.8rem;
`;

const SearchTrendGridContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-rows: 30.2rem;
  grid-template-columns: repeat(3, 41.6rem);
  gap: 2rem;
`;

const CountryList = styled.ul`
  width: 100%;
  height: 100%;
  align-content: center;
`;

interface CountryProps {
  $selectColor: string;
  $color: string;
  $isSelected: boolean;
  $hasMoreThanFive: boolean;
}

const Country = styled.li<CountryProps>`
  display: flex;
  border: 0.1rem solid var(--gray-300);
  justify-content: space-between;
  align-items: center;
  border-radius: 0.8rem;
  margin-top: 8px;
  padding: 0.8rem 2rem;
  height: ${props => props.$hasMoreThanFive ? '32px' : '4rem'};
  color: var(--gray-900);
  background-color: ${(props) => props.$isSelected ? props.$selectColor : 'transparent'};

  .country-name {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    line-height: 4rem;
    &::before {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      border-radius: 50%;
      border: 0.1rem solid black;
      display: inline-block;
    }
  }

  .country-score {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    border-radius: 1.2rem;
    padding: 0.2rem 1rem;
    background-color: ${(props) => props.$color};
    color: white;
  }
`;

const CountryChartListBase = styled.div`
  height: 100%;
  align-content: center;
  margin: 25px auto 20px;
`;

const CountryChartListFlex = styled(CountryChartListBase)`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const CountryChartListGrid = styled(CountryChartListBase)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  justify-content: center;
  row-gap: 20px;
  width: 80%;
`;

interface SearchReportProps {
  celebData: CelebData;
}

const SelectColorSet = ["#E0F2F1", "#E8EAF6", "#FFF8E1", "#FCE4EC", "#EFEBE9", "#F3E7FE"];

const SearchTrendReport = ({ celebData }: SearchReportProps) => {
  const [selectedCountry, setSelectedCountry] = useAtom<string>(celebCountryFilterAtom);

  // topSearchPercent 데이터를 최대 6개까지만 가져온다.
  const limitedTopSearchPercent = celebData.searchTrend?.topSearchPercent?.slice(0, 6) || [];

  if (
    celebData.searchTrend &&
    celebData.searchTrend.totalVolume !== undefined &&
    Array.isArray(celebData.searchTrend.topSearchPercent) &&
    celebData.searchTrend.topSearchPercent.length > 0
  ) {
    return (
      <Container>
        <ReportTitle>Search Trend</ReportTitle>
        <SearchTrendGridContainer>
          <GridCard>
            <CardTitle>
              Total search volume
              <HelpMessage>
                monthly average clickstream search volume
              </HelpMessage>
            </CardTitle>
            <CardData>
              {formatNumberWithCommas(celebData.searchTrend.totalVolume)}
            </CardData>
          </GridCard>
          <GridCard>
            <CardTitle>Popular countries by search volume</CardTitle>
            <CountryList>
              {limitedTopSearchPercent.map((data, index) => {
                return (
                  <Country
                    key={data.countryIsoCode}
                    $selectColor={SelectColorSet[index]}
                    $color={CountryColorSet[index]}
                    $isSelected={data.countryName === selectedCountry}
                    $hasMoreThanFive={limitedTopSearchPercent.length >= 6}
                  >
                    <span className="country-name">{data.countryName}</span>
                    <span className="country-score">{data.searchVolume}</span>
                  </Country>
                );
              })}
            </CountryList>
          </GridCard>
          <GridCard>
            <CardTitle>Popular countries by search percentage</CardTitle>
            {limitedTopSearchPercent.length >= 6 ? (
              <CountryChartListGrid>
                {limitedTopSearchPercent.map((data, index) => (
                  <AudienceGeoChart
                    key={celebData.celebInfo.id + data.countryIsoCode}
                    name={data.countryName}
                    value={data.percentage}
                    color={CountryColorSet[index]}
                    selectedCountry={selectedCountry}
                    items={limitedTopSearchPercent.length}
                  />
                ))}
              </CountryChartListGrid>
            ) : (
              <CountryChartListFlex>
                {limitedTopSearchPercent.map((data, index) => (
                  <AudienceGeoChart
                    key={celebData.celebInfo.id + data.countryIsoCode}
                    name={data.countryName}
                    value={data.percentage}
                    color={CountryColorSet[index]}
                    selectedCountry={selectedCountry}
                    items={limitedTopSearchPercent.length}
                  />
                ))}
              </CountryChartListFlex>
            )}
          </GridCard>
        </SearchTrendGridContainer>
      </Container>
    );
  }
  return <></>;
};

export default SearchTrendReport;
