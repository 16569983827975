import React, { useState } from "react";
import Section from "../layout/Section";
import styled from "styled-components";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import { CelebInfo } from "../interfaces/celeb.interfaces";
import {HashtagList, KeywordList} from "./Keyword";
import StyledButton from "../button/StyledButton";
import useStockCartList from "../../hooks/useStockCartList";
import { useNavigate } from "react-router-dom";
import StockCart from "./StockCart";
import FavoriteButton from "../button/FavoriteButton";
import Modals from "../modals/Modals";
import {ECelebType} from "./celebCard/CelebCard";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Close from "@mui/icons-material/Close";


const CelebName = styled.h1`
  font-size: 5.6rem;
  margin-top: 0.8rem;
  ${({ theme }) => theme.font.larken};
`;

const CelebTypeList = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const CelebType = styled.span`
  color: var(--gray-600);
  margin-top: 0.4rem;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
`;

const Wrapper = styled.div`
  display: flex;
  width: 144rem;
`;

const DefaultInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RequestProjectArea = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const ButtonAndFavorite = styled.div`
  display: flex;
  font-size: 4rem;
  align-items: center;
  gap: 2rem;
`;

const CartAndSearchMessage = styled.div<{ $length: number }>`
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;
  .cart-icon-wrapper {
    position: relative;
    cursor: pointer;
    background-image: url("/svg/cart.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 4rem;
    height: 4rem;
    &::after {
      display: ${(props) => (props.$length ? "block" : "none")};
      position: absolute;
      top: -0.4rem;
      right: -0.4rem;
      content: "${(props) => props.$length}";
      color: var(--white);
      width: 1.6rem;
      height: 1.6rem;
      text-align: center;
      align-content: center;
      font-size: 1rem;
      background-color: var(--crimson-600);
      border-radius: 50%;
    }
  }
  .search-message {
    cursor: pointer;
    color: var(--gray-500);
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.underline};
    ${({ theme }) => theme.font.italic};
    ${({ theme }) => theme.font.regular};
  }
`;

const OtherInformation = styled.div`
  margin-top: 2.4rem;
  padding: 1.2rem 2.4rem;
  border-top: 1px solid var(--gray-800);
  border-bottom: 1px solid var(--gray-800);
  display: flex;
  align-items: center;
  gap: 4rem;
  .bold {
    ${({ theme }) => theme.font.bold}
  }
`;

//const KeywordList = styled.div`
//  display: flex;
//  gap: 0.8rem;
//  margin-top: 1.2rem;
//  padding-left: 2.4rem;
//  padding-right: 2.4rem;
//  color: var(--gray-600);
//`;

const SocialIconList = styled.div`
  display: inherit;
  font-size: 2.4rem;
  gap: 1.6rem;
  .material-icons {
    cursor: pointer;
  }
`;

const HelpModal = styled.div`
  width: 42rem;
  border: 0.1rem;
  border-radius: 0.4rem;
  padding: 2rem;
  padding-bottom: 4rem;
  background: var(--white);
  box-shadow: 0rem 0rem 2rem 0rem rgba(71, 71, 71, 0.2);
  backdrop-filter: blur(0.2rem);
  position: absolute;
  text-align: center;
  display: flex;
  top: 4.8rem;
  flex-direction: column;
  gap: 1.2rem;
  z-index: 1;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  > .text {
    > b {
      ${({ theme }) => theme.font.bold};
    }
    > .mail {
      color: var(--purple-500);
      ${({ theme }) => theme.font.underline};
    }
  }
`;

const CloseModal = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  margin-left: auto;
  cursor: pointer;
  background-image: url("/svg/modal_close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const StyledButtonWrapper = styled.div`
  width: 42rem; /* Fixed width to match the button's normal size */
  height: 6rem; /* Fixed height for vertical alignment */
  padding: 2rem;
  display: flex;
  align-items: center;
`;

const ClosedCelebrityKeyword = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.8rem 0.8rem;
  padding: 8px;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
  background-color: #FF8F00; /* Light orange background */
  color: #FFF8E1; /* Orange text */
  border: 0.1rem solid #FF8F00; /* Border color */
  font-size: 1.4rem; /* Adjust font size */
  border-radius: 4px;

  .icon {
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    color: #FF8F00;
  }
`;

const CloseIcon = styled.p`
  margin-left: auto; /* Pushes 'x' to the far right */
  margin-right: 12px;
  display: flex;
  color: white;
  cursor: pointer;
  font-size: 1.6rem;
`;

const CelebDescription = styled.div`
  color: #7A7A7A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 12px;
`;

interface CelebNameSectionProps {
  celebInfo: CelebInfo;
}

const CelebNameSection = ({ celebInfo }: CelebNameSectionProps) => {
  const celebId = celebInfo.id;
  const celebType = celebInfo.type;

  const { stockList } = useStockCartList(celebId);
  const [cartOpenStatus, setCartOpenStatus] = useState(false);
  const [helpOpenStatus, setHelpOpenStatus] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isClickIcon, setIsClickIcon] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const cartOpenHandler = (value: boolean) => {
    if (value === true) {
      setHelpOpenStatus(false);
    }
    setCartOpenStatus(value);
  };

  const helpOpenHandler = (value: boolean) => {
    if (value === true) {
      setCartOpenStatus(false);
    }
    setHelpOpenStatus(value);
  };

  const onClickRequest = () => {
    const message = "This feature is currently under development.<br/> If you wish to proceed with a contract, please contact us directly at the email address below:"
    const addEmailWithFormattedMessage = `${message}<br/><br/> lahyun.kang@aligo.ai`;
    setModalMessage(addEmailWithFormattedMessage);
    setShowModal(true);
    // navigate("contract");
  };

  const onCloseIconClick = () => {
    setIsClickIcon(true)
  }

  return (
    <Section mt={6.6}>
      <Wrapper>
        <DefaultInformation>
          <HashtagList>
          {celebType === ECelebType.CLOSED && (
            <li className="closed">
              <img src={"/svg/closed/hover_person.svg"} className={"icon"} alt={"profiling"}/>
              <p>CLOSED CELEBRITY</p>
            </li>
            )}
            {celebInfo.tag?.map((item, index) => {
              return <li key={"keyword" + item + index}>{item}</li>;
            })}
          </HashtagList>
          {
            celebInfo.type === ECelebType.CLOSED ? (
              <CelebName>{celebInfo.fakeEnName}</CelebName>
            ) : (
              <CelebName>{celebInfo.name}</CelebName>
            )
          }
          <CelebTypeList>
            {celebInfo.types?.map((keyword, index) => {
              return (
                <CelebType key={"celebType_" + keyword + index}>
                  {keyword}
                </CelebType>
              );
            })}
          </CelebTypeList>
        </DefaultInformation>
        <RequestProjectArea>
          <ButtonAndFavorite>
            {
              celebInfo.type !== ECelebType.CLOSED ? (
                <>
                  <StyledButton
                    label="Request Contract"
                    size="lg"
                    color="black"
                    // disabled={isEmpty()}
                    onClick={() => onClickRequest()}
                  />
                  <FavoriteButton id={celebId} size={48} status={celebInfo.isLike} />
                </>
              ) : (
                <>
                  <StyledButtonWrapper/>
                  <FavoriteButton id={celebId} size={48} status={celebInfo.isLike} />
                </>
              )
            }
          </ButtonAndFavorite>
          {
            celebInfo.type !== ECelebType.CLOSED ? (
              <CartAndSearchMessage $length={stockList ? stockList.length : 0}>
                <div
                  className="cart-icon-wrapper"
                  onClick={() => {
                    cartOpenHandler(!cartOpenStatus);
                  }}
                />
                {cartOpenStatus && (
                  <StockCart celebId={celebId} closeFn={cartOpenHandler} />
                )}
                <span
                  className="search-message"
                  onClick={() => helpOpenHandler(!helpOpenStatus)}
                >
                  If you can’t search the photo you want?
                </span>
                {helpOpenStatus && (
                  <HelpModal>
                    <CloseModal onClick={() => helpOpenHandler(false)} />
                    <div className="text">
                      For additional contract terms such as
                      <br />
                      <b>
                        offline events, extra shoots, or exclusive
                        <br />
                        agreements,
                        <br />
                      </b>
                      please contact Celevu.
                      <br />
                      <br />
                      We will get back to you promptly.
                      <br />
                      <br />
                      <a href="mailto:support@aligo.ai" className="mail">
                        support@aligo.ai
                      </a>
                    </div>
                  </HelpModal>
                )}
              </CartAndSearchMessage>
            ) : (
              <StyledButtonWrapper></StyledButtonWrapper>
            )
          }
        </RequestProjectArea>
      </Wrapper>
      <OtherInformation>
        <span>
          Active From : <span className="bold">{celebInfo.activeFrom}</span>
        </span>
        <span>
          Management : <span className="bold">{celebInfo.management}</span>
        </span>
        <div>
        </div>
        <SocialIconList>
          {celebInfo.webUrl && (
            <a href={celebInfo.webUrl} target="_blank" rel="noreferrer">
              <LanguageIcon fontSize="inherit" />
            </a>
          )}
          {celebInfo.instaUrl && (
            <a href={celebInfo.instaUrl} target="_blank" rel="noreferrer">
              <InstagramIcon fontSize="inherit" />
            </a>
          )}
          {celebInfo.youtubeUrl && (
            <a href={celebInfo.youtubeUrl} target="_blank" rel="noreferrer">
              <YouTubeIcon fontSize="inherit" />
            </a>
          )}
        </SocialIconList>
      </OtherInformation>
      {
        celebInfo.type === ECelebType.CLOSED && !isClickIcon ? (
          <ClosedCelebrityKeyword>
            <img src={"/image/error.png"} alt="errorImg"/>
            <p>Closed celebrity is displayed with a pseudonym and a placeholder image. To access the actual details,
              please request permission.</p>
            <CloseIcon onClick={onCloseIconClick}>
              <Close />
            </CloseIcon>
          </ClosedCelebrityKeyword>
        ) : (
          // Close icon is clicked
          celebInfo.type !== ECelebType.CLOSED && (
            <>
              <CelebDescription>
                {celebInfo.description}
              </CelebDescription>
            </>
          )
        )
      }
      <KeywordList>
        {celebInfo.keyword?.map((data, index) => {
          return <li>{data}</li>;
        })}
      </KeywordList>
      {showModal && (
        <Modals
          message={modalMessage}
          onClose={closeModal}
        />
      )}
    </Section>
  );
};

export default CelebNameSection;
