import React from "react";
import styled, { css } from "styled-components";
import { CelebNews } from "../../interfaces/celeb.interfaces";
import formatDate from "../../../utils/utils";
import {ECelebType} from "../celebCard/CelebCard";

const newsCardCommonStyle = css`
  border-radius: 0.4rem;
  background-color: var(--gray-900);
  color: var(--white);
  border: 0.2rem solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  gap: 1.2rem;
  overflow: hidden;
  cursor: pointer;
  p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: normal;
    ${({ theme }) => theme.font.h3};
    ${({ theme }) => theme.font.bold};
  }
  &:hover {
    border: 0.2rem solid var(--purple-100, #ddc4fd);
    background-color: var(--gray-050);
    color: var(--gray-900);
  }
  .date {
    margin-left: auto;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const CelebImageNews = styled.div<{ $imageUrl: string }>`
  ${newsCardCommonStyle};
  width: 44rem;
  height: 42rem;
  padding: 4rem 3rem;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%); */
  }
`;

const CelebNewsSmContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CelebNewsSm = styled.div`
  ${newsCardCommonStyle};
  text-align: center;
  width: 30rem;
  height: 20rem;
  padding: 2.8rem;
  :first-child {
    ${({ theme }) => theme.font.title3};
    ${({ theme }) => theme.font.bold};
  }
`;

const CelebNewsMdContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const CelebNewsMd = styled.div`
  ${newsCardCommonStyle};
  width: 37.2rem;
  height: 42rem;
  padding: 4rem 2rem;
`;

const CelebNewsLgContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const CelebNewsLg = styled.div`
  ${newsCardCommonStyle};
  width: 71rem;
  height: 42rem;
  padding: 4rem;
`;

const CelebNewsXl = styled.div`
  ${newsCardCommonStyle};
  width: 76rem;
  height: 42rem;
  padding: 4rem;
`;

// const CelebNewsFull = styled.div`
//   ${newsCardCommonStyle};
//   width: 100%;
//   height: 42rem;
//   padding: 4rem;
// `;

interface NewsCardProps {
  celebType?: string;
  newsList: CelebNews[];
  dataStatus: boolean;
}

interface SingleNewsProps {
  firstNews: CelebNews;
  celebType?: string;
}

// const SingleNewsWithoutData = ({ firstNews }: SingleNewsProps) => {
//   return (
//     <CelebNewsFull onClick={() => window.open(firstNews.newsUrl, "_blank")}>
//       <p>{firstNews.title}</p>
//       <p className="date">{formatDate(firstNews.createdAt)}</p>
//     </CelebNewsFull>
//   );
// };

interface DoubleNewsProps {
  firstNews: CelebNews;
  secondNews: CelebNews;
}

const SingleNews = ({ firstNews, celebType }: SingleNewsProps) => {
  return (
    <>
      {
        celebType === ECelebType.CLOSED ? (
          <CelebNewsXl>
            <p>{firstNews.title}</p>
            <p className="date">{formatDate(firstNews.registerDate)}</p>
          </CelebNewsXl>
        ) : (
          <CelebNewsXl onClick={() => window.open(firstNews.newsUrl, "_blank")}>
            <p>{firstNews.title}</p>
            <p className="date">{formatDate(firstNews.registerDate)}</p>
          </CelebNewsXl>
        )
      }
    </>
  );
};

interface DoubleNewsProps {
  firstNews: CelebNews;
  secondNews: CelebNews;
  celebType?: string;
}

const DoubleNews = ({ firstNews, secondNews, celebType }: DoubleNewsProps) => {
  return (
    <CelebNewsMdContainer>
      {
        celebType === ECelebType.CLOSED ? (
         <>
           <CelebNewsMd>
             <p>{firstNews.title}</p>
             <p className="date">{formatDate(firstNews.registerDate)}</p>
           </CelebNewsMd>
           <CelebNewsMd>
             <p>{secondNews.title}</p>
             <p className="date">{formatDate(secondNews.registerDate)}</p>
           </CelebNewsMd>
         </>
        ):(
        <>
          <CelebNewsMd onClick={() => window.open(firstNews.newsUrl, "_blank")}>
            <p>{firstNews.title}</p>
            <p className="date">{formatDate(firstNews.registerDate)}</p>
          </CelebNewsMd>
          <CelebNewsMd onClick={() => window.open(secondNews.newsUrl, "_blank")}>
            <p>{secondNews.title}</p>
            <p className="date">{formatDate(secondNews.registerDate)}</p>
          </CelebNewsMd>
        </>
        )
      }
    </CelebNewsMdContainer>
  );
};

interface DoubleNewsWithoutDataProps {
  firstNews: CelebNews;
  secondNews: CelebNews;
}

const DoubleNewsWithoutData = ({
  firstNews,
  secondNews,
}: DoubleNewsWithoutDataProps) => {
  return (
    <CelebNewsLgContainer>
      <CelebNewsLg onClick={() => window.open(firstNews.newsUrl, "_blank")}>
        <p>{firstNews.title}</p>
        <p className="date">{formatDate(firstNews.registerDate)}</p>
      </CelebNewsLg>
      <CelebNewsLg onClick={() => window.open(secondNews.newsUrl, "_blank")}>
        <p>{secondNews.title}</p>
        <p className="date">{formatDate(secondNews.registerDate)}</p>
      </CelebNewsLg>
    </CelebNewsLgContainer>
  );
};

interface TripleNewsProps {
  firstNews: CelebNews;
  secondNews: CelebNews;
  thirdNews: CelebNews;
}

const TripleNews = ({ firstNews, secondNews, thirdNews }: TripleNewsProps) => {
  return (
    <>
      <CelebImageNews
        $imageUrl={firstNews.imgUrl}
        onClick={() => window.open(firstNews.newsUrl, "_blank")}
      >
        <div className="background" />
        <p>{firstNews.title}</p>
        <p className="date">{formatDate(firstNews.registerDate)}</p>
      </CelebImageNews>
      <CelebNewsSmContainer>
        <CelebNewsSm onClick={() => window.open(secondNews.newsUrl, "_blank")}>
          <p>{secondNews.title}</p>
          <p className="date">{formatDate(secondNews.registerDate)}</p>
        </CelebNewsSm>
        <CelebNewsSm onClick={() => window.open(thirdNews.newsUrl, "_blank")}>
          <p>{thirdNews.title}</p>
          <p className="date">{formatDate(thirdNews.registerDate)}</p>
        </CelebNewsSm>
      </CelebNewsSmContainer>
    </>
  );
};

const NewsCard = ({ celebType, newsList, dataStatus }: NewsCardProps) => {
  const firstNews = newsList[0];
  const secondNews = newsList[1];
  const thirdNews = newsList[2];

  const renderNews = () => {
    const newsLength = newsList.length;

    switch (newsLength) {
      case 0: {
        return <></>;
      }
      case 1: {
        return <SingleNews firstNews={firstNews} celebType={celebType} />;
      }
      case 2: {
        return <DoubleNews firstNews={firstNews} secondNews={secondNews} celebType={celebType} />;
      }
      default: {
        return (
          <TripleNews
            firstNews={firstNews}
            secondNews={secondNews}
            thirdNews={thirdNews}
          />
        );
      }
    }
  };

  if (dataStatus) {
    return renderNews();
  }

  return newsList.length > 2 ? (
    <DoubleNewsWithoutData firstNews={firstNews} secondNews={secondNews} />
  ) : (
    <SingleNews firstNews={firstNews} celebType={celebType} />
  );
};

export default NewsCard;
