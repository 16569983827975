import React from "react";
import styled from "styled-components";

interface ChartProps {
  $deg: number;
  $color: string;
  $isSelected?: boolean;
  $items?: number;
}

const CountryNameWrapper = styled.div`
  position: relative;
  justify-content: center;
  margin: 0;

  &:hover .tooltip {
    visibility: visible;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  border: 1px solid black;
  
  /* 툴팁 위치 */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  
  /* 화살표 */
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const Chart = styled.span<ChartProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .chart {
    position: relative;
    width: ${props => props.$items === 6 ? '72px' : '8rem'};
    height: ${props => props.$items === 6 ? '72px' : '8rem'};
    border-radius: 50%;
    transition: 0.3s;
    background: lightgray;
    display: inline-block;

    .chart-bar {
      width: inherit;
      height: inherit;
      border-radius: 50%;
      background: ${(props) =>
        `conic-gradient(${props.$color} ${props.$deg}deg, lightgray ${props.$deg}deg)`};
    }

    .chart-number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      ${({ theme }) => theme.font.body1}
      ${({ theme }) => theme.font.regular}
      color: ${props => props.$isSelected ? props.$color : 'inherit'};
    }
  }

  .chart:after {
    content: "";
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    // 더 얇은 도넛을 위해 내부 원 크기 증가
    width: ${props => props.$items === 6 ? '4.8rem' : '5.2rem'};
    height: ${props => props.$items === 6 ? '4.8rem' : '5.2rem'};
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .country-name {
    ${({ theme }) => theme.font.body3}
    ${({ theme }) => theme.font.regular}
    text-align: center;
    width: ${props => props.$items === 6 ? '72px' : '8rem'};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.$isSelected ? props.$color : 'inherit'};
    font-weight: ${props => props.$isSelected ?  'bold' : 'normal'};
  }
`;

interface AudienceChartProps {
  name: string;
  value: number;
  color: string;
  selectedCountry?: string;
  items?: number;
}

const AudienceGeoChart = ({
                            name,
                            value,
                            color,
                            selectedCountry,
                            items,
                          }: AudienceChartProps) => {
  const result = (360 / 100) * value;
  const isSelected = selectedCountry === name;

  return (
    <Chart $deg={result} $color={color} $isSelected={isSelected} $items={items}>
      <div className="chart">
        <div className="chart-bar"></div>
        <span className="chart-number">{value.toFixed(1)}%</span>
      </div>
      <CountryNameWrapper>
        <div className="country-name">{name}</div>
        <Tooltip className="tooltip">{name}</Tooltip>
      </CountryNameWrapper>
    </Chart>
  );
};

export default AudienceGeoChart;
