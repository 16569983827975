import React from "react";
import styled from "styled-components";
import {putClosedCelebPermission, sendClosedCelebPermission} from "../../api/celebPermission";

const Overlay = styled.div`
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3); /* Dimmed background */
	backdrop-filter: blur(1px); /* Blur effect */
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ModalWrapper = styled.div`
	width: 400px;
	background: #fff;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	text-align: center;
`;

const IconContainer = styled.div`
	background: #fef3c7; /* Light yellow */
	color: #f59e0b; /* Icon color */
	border: 1px solid #FF6F00;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	margin-bottom: 8px;

	img {
		border-radius: 50%;
		width: 24px;
		height: 24px;
	}
`;

const Title = styled.h2`
	margin-bottom: 40px;
	font-size: 20px;
	font-weight: 600;
	color: #252525;
`;

const Message = styled.p`
	margin-bottom: 40px;
	font-size: 16px;
	color: #444444;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;
	width: 100%;
`;

const Button = styled.button<{ primary?: boolean }>`
	flex: 1;
	width: 160px;
	height: 60px;
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 500;
	border-radius: 2px;
	border: 1px solid ${(props) => (props.primary ? "#252525" : "#CCCCCC")};
	background-color: ${(props) => (props.primary ? "#252525" : "#FFFFFF")};
	color: ${(props) => (props.primary ? "#FFFFFF" : "#252525")};
	cursor: pointer;

	&:hover {
		background-color: ${(props) => (props.primary ? "#444444" : "#F5F5F5")};
	}
`;

interface IPermissionRequestModalProps {
	onClose: () => void; // Function to close the modal
	celebId: string; // Celeb ID
	setIsSuccessModal: (value: boolean) => void; // Function to set the success modal
}

const ClosedPermissionRequestModals = ({ onClose, celebId, setIsSuccessModal }: IPermissionRequestModalProps) => {
	const handleSubmit = async () => {
		try {
			const response = await putClosedCelebPermission(celebId);
			await sendClosedCelebPermission(celebId);
			setIsSuccessModal(true);
			onClose();
		} catch (error) {
			console.error("Failed to request permission", error);
			onClose();
		}
	};

	return (
		<Overlay>
			<ModalWrapper>
				<IconContainer>
					<img src={"/image/closed_person.svg"} className={"icon"} alt={"profiling"}/>
				</IconContainer>
				<Title>Permission Request</Title>
				<Message>Do you want to request permission?</Message>
				<ButtonContainer>
					<Button onClick={onClose}>Cancel</Button>
					<Button primary onClick={handleSubmit}>Submit</Button>
				</ButtonContainer>
			</ModalWrapper>
		</Overlay>
	);
};

export default ClosedPermissionRequestModals;
