// ContactUsPage.tsx
import { LayoutWithOutFooter } from "../components/layout/Layout";
import styled from "styled-components";
import ContactUsInput from "../components/input/ContactUsInput/ContactUsInput";
import ContactUsMessageInput from "../components/input/ContactUsInput/ContactUsMessageInput";
import {useState} from "react";
import { PostContactUsForm } from "../components/interfaces/contact-us.interface";
import { postContactUs } from "../api/contact-us";
import ContactUsReceived from "../components/contactus/ContactUsReceived";

const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  display: flex;
  gap: 80px;
`;

const ContentSection = styled.div`
  flex: 1;
`;

const FormSection = styled.div`
	flex: 1;
	background: white;
	padding: 90px 84px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	border: 1px solid var(--Secondary-Secondary-100, #DDC4FD);
	background: var(--Uncategorized-White, #FFF);
`;

const Title = styled.h1`
	align-self: stretch;
	font-family: Larken, serif;
	font-size: 60px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 72px */
  color: #333;
  margin-bottom: 16px;
`;

const SubmitButton = styled.button`
  width: 100%;
	height: 60px;
  padding: 15px;
  background: #AE6EFD;
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #8A35FF;
  }
`;

const ContactUsPage = () => {
	const [showForm, setShowForm] = useState(true);
	const [formData, setFormData] = useState<PostContactUsForm>({
		companyName: '',
		email: '',
		message: ''
	});

	const handleChange = (field: keyof PostContactUsForm) => (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setFormData(prev => ({
			...prev,
			[field]: e.target.value
		}));
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			await postContactUs(formData);
			// 폼 초기화
			setFormData({
				companyName: '',
				email: '',
				message: ''
			});
			setShowForm(false);
		} catch (error) {
			console.error('Contact form submission error:', error);
			// 에러 처리 로직 추가 가능
		}
	};

	return (
		<LayoutWithOutFooter>
			<PageWrapper>
				<ContentSection>
					<Title>Need support?</Title>
					<Title>We're here to help.</Title>
					{/* 이미지 위치 */}
					<img src="/svg/contactus/contact-us.svg" alt="Support Illustration" />
				</ContentSection>
				{
					showForm ?(
						<FormSection>
							<form onSubmit={handleSubmit}>
								<ContactUsInput
									label="Company Name"
									placeholder=""
									value={formData.companyName}
									onChange={handleChange('companyName')}
								/>
								<ContactUsInput
									label="Email"
									type="email"
									placeholder="email@aligo.com"
									value={formData.email}
									onChange={handleChange('email')}
								/>
								<ContactUsMessageInput
									label="Message"
									placeholder="What would you like to talk about?"
									value={formData.message}
									onChange={handleChange('message')}
								/>
								<SubmitButton type="submit">Submit</SubmitButton>
							</form>
						</FormSection>
					) : (
						<ContactUsReceived
							img={"/svg/contactus/check_small.svg"}
							title="Thanks!"
							message="Your submission has been received!"
						/>
					)
				}
			</PageWrapper>
		</LayoutWithOutFooter>
	);
};

export default ContactUsPage;
