import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
	z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(2px); /* 블러 효과 */
	-webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBoxContainer = styled.div`
  background: #fff;
	min-width: 420px;
	min-height: 266px;
  padding: 42px;
  border-radius: 8px;
  text-align: center;
`;

const ModalContentContainer = styled.div`
	display: flex;
	flex-direction: column; /* 수직으로 정렬 */
	justify-content: center; /* 세로 중앙 정렬 */
	align-items: center; /* 가로 중앙 정렬 */
	height: 100%; /* 부모 요소의 높이를 100%로 설정 */
	min-width: 380px;
	min-height: 226px;
`;

const ModalMessageContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 114px;
	justify-content: center;
	font-size: 16px;
	margin: 0 auto;
`;

const CloseButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100px;
	width: 100%;
	margin-top: auto;
`;

const CloseButton = styled.button`
  background-color: #252525;
  color: #fff;
  border-radius: 2px;
	min-width: 160px;
	min-height: 60px;
	margin: 0 auto;

  &:hover {
		cursor: pointer;
		background-color: #444444;
  }
`;

interface IModalProps {
  message: string | string[]; // 배열 또는 문자열로 정의
  onClose: () => void; // 모달 닫기 함수를 전달받는 prop
}

const Modals = ({ message, onClose }: IModalProps) => {
  return (
    <Wrapper>
      <ModalBoxContainer>
        <ModalContentContainer>
          <ModalMessageContainer>
            {Array.isArray(message) ? (
              message.map((msg, index) => <p key={index}>{msg}</p>)
            ) : (
              // HTML 태그를 포함한 문자열 처리
              <div dangerouslySetInnerHTML={{ __html: message }} />
            )}
          </ModalMessageContainer>
          <CloseButtonContainer>
            <CloseButton onClick={onClose}>OK</CloseButton>
          </CloseButtonContainer>
        </ModalContentContainer>
      </ModalBoxContainer>
    </Wrapper>
  );
};

export default Modals;
