import React, { ReactNode } from "react";
import styled from "styled-components";
import { Help } from "./Icon";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  
  .message-box {
    display: none;
    width: 24rem;
    white-space: pre-wrap;
    padding: 1.2rem 1.6rem;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
    text-align: center;
    background-color: var(--purple-050);
    position: absolute;
    left: 3.2rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0.4rem;
    &::before {
      position: absolute;
      left: -1rem;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.8rem 1.6rem 0.8rem 0rem;
      border-color: transparent var(--purple-050) transparent transparent;
    }
  }
  &:hover {
    .message-box {
      display: block;
    }
  }
`;

interface HelpMessageProps {
  children: ReactNode;
}

const HelpMessage = ({ children }: HelpMessageProps) => {
  return (
    <Container>
      <Help />
      {children && <span className="message-box">{children}</span>}
    </Container>
  );
};

export default HelpMessage;
