import {getEnv, publicClient} from "./axiosInstance";

export const SendResetPassword = async (email: string) => {
	const body= { email }
	const response = await publicClient.post(
		`${getEnv()}api/v1/email/reset-password`,
		body
	);
	return response.data;
};
